@import './sass/variables';
@import '@fgb/portal-component-library/styles';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';

/* ELEMENT STYLING */
html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;

    @if $light-theme {
      background: $white;
      color: black;
    } @else {
      background: $white;
      color: white;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
.title-font {
  font-weight: normal !important;
  text-transform: none;
  font-family: $font-heading;
}

h2 {
  font-size: 22px !important;
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.nav.nav-secondary {
  box-shadow: 0 4px 2px -1px rgba(0, 0, 0, 0.15);
}

.btn-circle {
  border-radius: 50%;
}

$primary: $primary-color;

/* BOOTSTRAP OVERRIDES */
$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'tertiary': $tertiary-color,
  'quaternary': $quaternary-color,
  'primary-alternate': $primary-alternate,
  'muted': $muted-color,
  'disabled': $disabled-color,
  'info': $info-color,
  'grey': $grey-color,
  'success': $success-color,
  'danger': $error-color,
  'auction': $auction-color,
  'marketplace': $marketplace-color,
  'mp-unique-code': $tertiary-color,
  'mp-download': $secondary-color,
  'mp-event': $events-color,
  'mp-virtual-event': $events-color,
  'mp-product': $tertiary-color,
  'lotto': $lotto-color,
  'wallet': $wallet-color,
  'points': $points-color,
  'ecash': $ecash-color,
  'predictor': $predictor-color,
  'poll': $poll-color,
  'quiz': $quiz-color,
  'affiliate': $primary-color,
  'transfer': $primary-color,
  'white': #ffffff,
  'black': #000000,
  'sold-out': #70707033,
  'coming-soon': #70707033,
  'limit-reached': #e03e3e33,
  'not-enough-points': #e03e3e33,
  'add-card': $primary-color,
  'active-card': #117aca,
  'inactive-card': $disabled-color,
  'frozen-card': $frozen-color,
  'announcements': $primary-color,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

$sizes: (
  90: 90%,
);

$grid-gutter-width: 22px;

$enable-rounded: true;
$enable-validation-icons: false;

$border-radius-base: 4px;

$box-shadow-sm: 0 0 0.25rem rgba(#000, 0.15);
$box-shadow: 0 0.1rem 0.5rem rgba(#000, 0.15);

$date-select-box-shadow: 0 0 0 0.2rem rgb(60 85 124 / 25%);

/* Form Colors */

.form-control {
  color: #000000;
}

/* eCash Styling */

@media (min-width: 1200px) {
  .topUpModal .modal-dialog {
    max-width: 1140px;
  }
}

/* Banner */

.banner {
  @media (min-width: 992px) {
    padding-left: 3rem;
    background: url('../src/assets/images/banner-desktop.png');
  }
  @media only screen and (max-width: 991px) {
    background: url('../src/assets/images/banner.png');
  }
  background-size: cover !important;
  justify-content: left;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.login-banner {
  background-image: url(~src/assets/images/login-registration/login-banner-demo.jpg);
  background-size: cover;
  background-position: center;
}

/* Placeholders */

.no-items {
  .no-results-placeholder-title {
    font-family: $font-heading;
    font-size: 25px;
    font-weight: 400;
  }

  .no-results-placeholder-description {
    font-size: 0.875rem;
    color: $black;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid red;
  }
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.filter-modal-dialog {
  display: flex;
  align-items: flex-end !important;
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: $border-radius;
  overflow: auto;
  color: #000;
  padding: 0 10px 15px 15px;
}

/* Placeholder Styling */

.no-items {
  .no-results-placeholder-icon {
    max-width: 200px;
    margin: 18px auto;

    &.material-icons-two-tone {
      font-size: 4rem;
      margin: 18px auto 0;
    }
  }

  .no-results-placeholder-title {
    font-size: 1rem;
    font-family: $font-heading;
  }

  .no-results-placeholder-text {
    font-size: 0.75rem;
  }

  &.no-results-placeholder-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-color;
    height: 50px;
    @media (max-width: 991px) {
      margin: 0px;
    }

    .no-results-placeholder {
      font-size: 0.75rem;
    }
  }
}

.increment-button,
.decrement-button {
  svg {
    height: 13px;
    width: 13px;
  }
}

.redeem-list-item-icons {
  font-size: 18px;
}

/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  background-color: white !important;
  color: $error-color !important;
  border: none !important;
}

.all-offers-steps {
  .card-link-earn-step-box {
    .card-title {
      font-size: 14px;
      color: #c80550;
    }
    .color-1 {
      color: #c80550;
    }
    .color-2 {
      color: #82bee6;
    }
    .color-3 {
      color: #7d4605;
    }
    .card-link-earn-step-icon-border-1 {
      border: 1px solid #c80550;
    }
    .card-link-earn-step-icon-border-2 {
      border: 1px solid #82bee6;
    }
    .card-link-earn-step-icon-border-3 {
      border: 1px solid #7d4605;
    }
  }
}

.offer-button {
  span {
    font-family: 'Material Icons';
  }
}

.nav.nav-secondary {
  .nav-item {
    &.active,
    &:active,
    &:hover {
      background-color: transparent;
    }
    background-color: transparent;
  }
}

fgbcl-number-input {
  .quantity-select-container {
    margin: auto !important;
  }
}

.register-panel {
  .container {
    > *:not(router-outlet) {
      display: flex;
      flex-direction: column;
      height: inherit;
    }
  }
}

.registration-payment-modal {
  .registration-payment-modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    background-color: $primary-color;
    color: #ffffff;

    .registration-payment-modal-title {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 18px;
    }

    .close-btn {
      cursor: pointer;
      margin-left: auto;
      font-family: 'Material Icons Outlined';
    }
  }

  .payment-container {
    display: flex;
    justify-content: center;
  }

  .payment-form {
    max-width: 310px;
  }
}

.invalid-token-modal {
  max-width: 700px;

  .modal-content {
    min-height: 280px;
    padding: 15px;
  }
}

/** Popup home screen shortcut */

.popup-add-to-home-screen-ios-modal-window {
  &.modal {
    height: fit-content;
    top: unset;
    bottom: 0px;
  }
}

.popup-add-to-home-screen-android-modal-window {
  &.modal {
    .modal-dialog {
      width: 290px;
      float: right;
    }
  }
}

.popup-add-to-home-screen-dialog {
  .modal-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

/** Games Page */
.games {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }

          .survey-container {
            &.bg-poll {
              @media (max-width: 991px) {
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

/** Scorecard Carousel */
.badge-carousel-div {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }
        }
      }
    }
  }
}

.container.py-4.badge-carousel-div {
  padding-bottom: 0 !important;
  padding-top: 0.5rem !important;
}

.bg-carousel {
  min-height: 290px !important;
}

/* Tertiary */

.modal-content {
  border-radius: 4px !important;
}

.faq-section-card-title {
  font-family: $font-default;
  font-size: 0.875rem !important;
  font-weight: bold;
}

.faq-section-card-description {
  font-size: 0.875rem !important;
}

.faq-container .accordion .accordion-button:not(.collapsed) {
  background-color: $black !important;
}

.faq-container .accordion .accordion-item:first-of-type > .accordion-header .accordion-button,
.faq-container .accordion .accordion-item:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.faq-container .accordion .accordion-item:last-of-type > .accordion-header .accordion-button.collapsed,
.faq-container .accordion .accordion-item:last-of-type {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

/* Registration */

fgbcl-registration-navigation {
  .btn-next-step {
    border-radius: 16px !important;
  }
  .btn-previous-step {
    background-color: transparent !important;
    border: none !important;
    color: $error-color !important;
  }
  .justify-content-between {
    justify-content: end !important;
    border-top: 1px solid #dedddd;
    padding-top: 20px;
  }
}

fgbcl-registration-terms-and-conditions {
  .form-check .form-check-input:checked {
    background-color: $success-color !important;
    border-color: $success-color !important;
  }
  .registration-terms-and-condition .content {
    max-height: calc(100vh - 400px);
    @media all and (max-width: 991px) {
      max-height: calc(80vh - 110px);
    }
  }
  .wrap-accept-box {
    margin-top: 15px !important;
  }
  h5 {
    font-size: 22px !important;
  }
  .wrap-accept-box {
    margin-bottom: 20px;
    padding-bottom: 0 !important;
  }
  .registration-terms-and-condition h5 {
    color: $primary-color;
    margin-bottom: 0;
  }
  .terms-section-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .accept-box-label {
    font-size: 14px !important;
  }
  .registration-navigataion-button-container {
    margin-top: 0;
    padding-top: 15px;
    border-top: 1px solid $disabled-color;
  }
}

/* Scorcards */

.scorecard-description-linked-action .scorecard-description-locked-box {
  border-radius: 16px !important;
}

.badge-achieved-title {
  text-transform: capitalize;
}

fgbcl-scorecard-list-view {
  .container.my-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

#scorecard-name {
  font-family: $font-heading;
}

.scorecard-linked-shadow::before,
.scorecard-linked-shadow::after {
  border-radius: 4px 4px 0 0;
}

.scorecard-container-radius,
.scorecard-locked-header,
.badge-container {
  border-radius: 4px !important;
}

.scorecard-description-linked-action .scorecard-description-prizebox {
  border-radius: 4px !important;
}

/* Payment */

.top-up-address-modal {
  .modal-content {
    border-radius: 4px !important;
    .form-floating {
      label {
        font-family: $font-default;
        color: $info-color !important;
      }
    }
  }
}

fgb-transaction-history {
  @media (max-width: 991px) {
    fgbcl-date-range-picker {
      .to-date-text {
        margin-top: 10px !important;
      }
    }
    .search-button {
      margin-top: 20px !important;
    }
  }
  .no-results-placeholder-description {
    font-size: 12px !important;
  }
}

.card-issuer-bg {
  background-image: url('~src/assets/images/payment-icons/chase-white-logo.png');
  background-position: center;
  background-repeat: no-repeat;
}

.payment-card .card-status {
  border-top-left-radius: 0;
}

.btn.add-card {
  background-color: $black !important;
  border-color: $black !important;
  border-radius: 4px !important;
  @media (max-width: 991px) {
    border-radius: 16px !important;
    margin-top: 5px;
  }
  .icon {
    margin-right: 0;
  }
}

.add-card-label {
  text-transform: capitalize !important;
}

fgbcl-ecash-description {
  .d-flex.flex-column.flex-nowrap.mx-2.py-2:first-of-type {
    @media (min-width: 992px) {
      .ecash-description-number {
        left: -1.5em;
      }
    }
  }
  .ecash-description-box .ecash-description-icon .ecash-description-number {
    left: -2.1em;
    height: 31px;
    width: 31px;
  }
  @media (max-width: 991px) {
    .ecash-description-box .ecash-description-icon .ecash-description-number {
      left: -1.5em;
      height: 31px;
      width: 31px;
    }
    .d-flex.flex-row.flex-wrap.flex-lg-nowrap.justify-content-lg-around.mt-3.ms-2 {
      margin-left: 0 !important;
    }
    .d-flex.flex-column.flex-nowrap.mx-2.py-2 {
      width: 100%;
      .d-flex.flex-row.justify-content-center.align-items-start {
        justify-content: start !important;
        .ecash-description-icon {
          margin-left: 15px;
        }
      }
    }
  }
  .d-flex.flex-column.flex-nowrap.mx-2.py-2 {
    @media (min-width: 992px) {
      width: 33.3%;
      &:nth-of-type(2) {
        margin-left: 40px !important;
      }
    }
  }
  h5 {
    font-size: 20px;
  }
  hr {
    opacity: 1;
    border-top-color: $disabled-color !important;
  }

  .ecash-description-number,
  .ecash-description {
    color: $primary-color !important;
  }

  .ecash-description.color-2,
  .ecash-description.color-3 {
    @media (min-width: 992px) {
      margin-left: -10px;
    }
  }

  .d-flex.flex-row.justify-content-center.align-items-start {
    align-items: center !important;
  }
}

fgbcl-card-token-list {
  .payment-cards-title {
    display: none;
  }

  .btn-status.w-80.activate {
    width: 100% !important;
  }
}

/* Payment Modal */

.invalid-feedback {
  font-weight: normal !important;
}

.country-and-regions .form-floating .form-select {
  height: auto !important;
}

#paymentGPForm {
  div:nth-child(6) {
    text-align: center;
  }

  #submitPayment {
    border: none;
    border-radius: 16px !important;
    height: 34px;
    background-color: $primary-color;
    color: $white;
    font-size: 0.875rem;
    margin-top: 15px;
    font-weight: bold;
  }

  div {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  div:nth-child(5) {
    flex-direction: row !important;
  }

  #globalpaymentexpirationmonth,
  #globalpaymentexpirationyear {
    width: 44%;
  }

  #globalpaymentexpirationmonth {
    margin-right: 10px;
  }

  #globalpaymentexpirationyear {
    margin-left: 10px;
  }
}

.countdown-timer {
  font-family: $font-heading;
  font-size: 14px !important;
}

@import 'bootstrap/scss/bootstrap';

/* Requiring Bootstrap */
@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;
    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }
    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}

.fw-bold.material-icons {
  font-family: 'Material Icons' !important;
}

.nav.nav-secondary {
  height: 56px;
  .nav-item label {
    display: block;
    color: $primary-color;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .nav-item.active {
    div {
      border-bottom: 4px solid $primary-color !important;
      width: 60%;
      margin: 0 auto;
      position: relative;
      top: 8px;
    }
    label {
      opacity: 1 !important;
    }
  }

  .nav-item:active,
  .nav-item:hover {
    border-bottom: none !important;
  }

  .nav-item {
    padding: 6px 0;
  }
}

/***** NAVBAR *****/
.mobile-nav-menu {
  .nav-child {
    &:not(:first-child) {
      padding-top: 0.8rem;
    }
    a {
      color: white;
    }
    p {
      margin-bottom: 0 !important;
    }
  }
}

/* Wallet */

fgbcl-rewards-wallet-redeem-list {
  .marketplace-wallet-download-btn {
    border: none !important;
    color: $white;
  }
  .item-count {
    color: $black;
  }
  .action-wrapper {
    button {
      border-radius: 16px !important;
      background-color: $black !important;
    }
  }
}

.marketplace-wallet-points-values {
  display: none !important;
}

.wallet-info-box {
  border-radius: 4px !important;
}

.wallet-unique-code-item {
  color: $black !important;
}

fgbcl-marketplace-wallet-details,
fgbcl-lottos-wallet-details {
  .marketplace-wallet-download-btn {
    border: none !important;
    background-color: $black !important;
    border-radius: 16px !important;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  }
  .accordion-button:not(.collapsed) {
    color: $black;
  }
  .wallet-item-card {
    border-radius: 16px;
  }
  .wallet-points-value {
    display: none !important;
  }
  .d-flex.mt-3.pt-3 {
    margin-top: 0.25rem !important;
  }
}

.wallet-description-divider {
  display: none;
}

fgbcl-rewards-wallet-redeemed-list {
  .wallet-marketplace-item-name {
    font-size: 12px !important;
  }
  .wallet-container {
    background-color: $white;
  }
}

.redeem-card {
  border-radius: 4px !important;
}

/* Announcements */

.announcements-carousel {
  .owl-carousel .owl-stage-outer {
    padding-left: 0px !important;
  }
}

.announcements-carousel .owl-item {
  padding-left: 0 !important;
}

.announcement-coming-soon {
  height: 150px;

  .countdown-timer {
    font-size: 30px !important;
  }
}

/* Campaign Offers */

fgbcl-campaigns-offers-list {
  .campaign-list-item-card {
    background: $white;
    border-radius: 4px;
  }
  h5 {
    font-size: 22px;
  }
}

fgb-hawks-bucks-page {
  .number-input-increment-button {
    background-color: $primary-color !important;
    color: $white !important;
  }
}

.btn-rounded {
  border-radius: 50px;
}

/* Questions */

fgbcl-edit-questions {
  .btn {
    border-radius: 16px !important;
  }

  .card {
    border: 1px solid #dedddd !important;
  }

  .edit-questions .wrap-btn {
    justify-content: end;

    .cancel {
      background-color: transparent;
      color: $primary-color !important;
    }
  }

  .edit-questions .wrap-btn .cancel:hover,
  .edit-questions .wrap-btn .cancel:focus {
    background-color: transparent !important;
  }
}

/** rewards */

.rewards-button-background {
  border-radius: 4px;
}

fgbcl-mixed-loyalty-list,
fgbcl-featured-rewards {
  .loyalty img {
    border-radius: $border-radius;
  }
  .rewards-item-panel-value {
    color: $black;
  }
  #merchan-value,
  #ecash-value {
    display: block;
  }
  .rewards-item-panel-value {
    display: none;
  }
  .countdown-timer {
    font-family: $font-default-Bold;
  }
}

fgbcl-marketplace-details,
fgbcl-lotto-details {
  .accordion-item {
    background-color: transparent;
  }
  .rewards-terms-label {
    margin-left: 0.5rem !important;
  }
  .form-check {
    display: flex;
    align-items: center;
  }
  .form-select:focus {
    border-color: unset;
  }
  .form-control:focus {
    border-color: unset;
    box-shadow: none;
  }
  .loyalty-details-image.card {
    @media (min-width: 992px) {
      flex: 0 0 470px;
      border-radius: $border-radius;
      img {
        border-radius: $border-radius;
      }
    }
  }
  .number-entries {
    font-size: 12px !important;
  }
  .rewards-item-name {
    font-size: 20px !important;
  }
  .rewards-item-details {
    color: $black;
  }
  .form-check-input:checked {
    background-color: $success-color !important;
    border-color: $success-color !important;
  }
  #points-value {
    display: none;
  }
}

fgbcl-marketplace-purchase-confirmation,
fgbcl-confirm-modal {
  .quantity-value,
  .confirmation-attribute-value,
  .confirmation-custom-field-value {
    font-size: 14px;
  }
  .point-value-section {
    display: none;
  }
  hr {
    border-color: $disabled-color;
    opacity: 1;
  }
  .rewards-confirm-divider {
    @media (min-width: 992px) {
      margin-bottom: 20px !important;
    }
  }
  .confirm-modal-image {
    border-radius: $border-radius;
  }
}

fgbcl-marketplace-details:has(fgbcl-marketplace-custom-fields) {
  .form-check {
    margin-top: 0 !important;
  }
}

fgbcl-confirm-modal {
  .lotto-modal-points-value {
    display: none;
  }
  .lotto-modal-entries-total {
    font-size: 14px;
  }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: $disabled-color !important;
}

fgbcl-announcements {
  h2 {
    margin-top: 24px;
  }
}
.featured-rewards-show-all,
.scorecard-featured-link {
  font-size: 12px;
}
.featured-rewards-heading {
  font-size: 22px;
}
.campaigns-offers-list .offer-items-header .offer-items-header-link {
  font-size: 12px;
  font-weight: 400;
  .offer-items-header-link-icon {
    padding-left: 5px;
    font-weight: 700;
  }
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: $black !important;
}

// Scorecard changes

.scorecard-milestone-value {
  color: $secondary-color;
}

fgbcl-scorecard-list-view {
  #scorecard-name {
    border-radius: 16px !important;
  }
}

.scorecard-description-carousel-header .scorecard-description-prize .prize-label,
.scorecard-description-carousel-header .prize-content {
  color: $secondary-color;
}

fgbcl-scorecard-description {
  .scorecard-description-linked-action .scorecard-description-prizebox,
  .scorecard-description-share .share-icon {
    background-color: $secondary-color !important;
    border: $secondary-color !important;
  }
  .scorecard-description-linked-action .scorecard-description-congratulation-title,
  .scorecard-description-desc-terms .accordion-button:not(.collapsed) {
    color: $secondary-color;
  }
  .share-text {
    font-size: 12px !important;
  }

  .scorecard-modal-content .modal-content {
    max-height: 520px;
  }
}

/** Transfer Hawks Bucks Page */
fgb-transfer-arena-cash-page {
  @media (max-width: 991px) {
    h5 {
      font-size: 20px;
    }
    .container fgb-transaction-history {
      h2 {
        font-size: 20px !important;
      }
    }
  }
  h5 {
    font-size: 22px;
  }
  .container fgb-transaction-history {
    h2 {
      font-size: 22px !important;
    }
  }
}

.modal-content:has(.transfer-confirm-modal) {
  border-radius: 4px !important;
}

@media (max-width: 991px) {
  .nav.nav-secondary .nav-item.active {
    border-bottom: 0;
  }
}

.scorecard-description-share,
.loyalty-share {
  display: none;
}

fgbcl-cart-item {
  .font-size-12.mt-2.text-danger {
    display: none;
  }
}
